<template>
  <div>
    <el-card
      :body-style="{ padding: '2%' }"
      style="margin: 1.5%"
      shadow="never"
    >
      <div class="aiuda">
        <span style="font-size: 24px; font-weight: 600">Nuevo Centro</span>
        <el-button class="button" @click="$router.go(-1)"
          >Volver atrás</el-button
        >
      </div>
      <el-divider />
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        label-position="top"
        label-width="130px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Razon Social" prop="razonSocial">
              <el-input maxlength="20" v-model="form.razonSocial"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="CUIT" prop="cuit">
              <input-mask
                class="el-input__inner"
                v-model="form.cuit"
                mask="99-99.999.999-9"
                maskChar=" "
              ></input-mask>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Email" prop="email">
          <el-input
            class="inputEmail"
            maxlength="300"
            v-model="form.email"
          ></el-input>
        </el-form-item>

        <el-form-item label="Domicilio" prop="domicilio">
          <el-input maxlength="30" v-model="form.domicilio"></el-input>
        </el-form-item>
        <div class="contenedor">
          <el-form-item label="Departamento" prop="departamentoID">
            <el-select
              v-model="form.departamentoID"
              class="m-2"
              placeholder="Seleccione un departamento de la lista"
              @change="cambioDepartamento"
            >
              <el-option
                v-for="item in departamentosSelect"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            v-loading="cargandoLocalidades"
            label="Localidad"
            prop="localidadID"
          >
            <el-select
              :disabled="!form.departamentoID"
              v-model="form.localidadID"
              class="m-2"
              placeholder="Seleccione una localidad de la lista"
              @change="cambioLocalidad"
            >
              <el-option
                v-for="item in localidadesSelect"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="contenedor">
          <el-form-item label="Formato de coordenadas" prop="latLongFloat">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueFormatoDecimales"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="Decimales"
              inactive-text="Grados, minutos y segundos"
            >
            </el-switch>

            <label style="color: gray; font-style: italyc"
              >Se almacenará el formato seleccionado</label
            >
          </el-form-item>

          <div v-if="valueFormatoDecimales">
            <el-form-item label="Latitud" prop="latitud">
              <el-input v-model="form.latitud" @input="onPasteLat"></el-input>
            </el-form-item>

            <el-form-item label="Longitud" prop="longitud">
              <el-input v-model="form.longitud" @input="onPasteLong"></el-input>
            </el-form-item>
          </div>

          <div v-else>
            <el-row :gutter="20">
              <el-col :span="3">
                <el-form-item label="." prop="grados">
                  <label>Latitud</label>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Grados" prop="gradosLat">
                  <el-input v-model.number="form.gradosLat">
                    <template slot="append">°</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Minutos" prop="minutosLat">
                  <el-input v-model.number="form.minutosLat">
                    <template slot="append">'</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Segundos" prop="segundosLat">
                  <el-input v-model.number="form.segundosLat">
                    <template slot="append">''</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Direccion" prop="valueCardinalLat">
                  <el-select
                    v-model="form.valueCardinalLat"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in puntosCardinales"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="3">
                <el-form-item label="." prop="grados">
                  <label>Longitud</label>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Grados" prop="gradosLon">
                  <el-input v-model.number="form.gradosLon">
                    <template slot="append">°</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Minutos" prop="minutosLon">
                  <el-input v-model.number="form.minutosLon">
                    <template slot="append">'</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="Segundos" prop="segundosLon">
                  <el-input v-model.number="form.segundosLon">
                    <template slot="append">''</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item label="Direccion" prop="valueCardinalLon">
                  <el-select
                    v-model="form.valueCardinalLon"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="item in puntosCardinales"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Teléfono" prop="telefono">
              <el-input maxlength="15" v-model="form.telefono"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Tipo de Centro" prop="tipo">
          <maca-select-box
            v-model="form.tipo"
            :url="urlTiposBeneficiarios"
          ></maca-select-box>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Traslados" prop="traslados">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueTraslados"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Crear Tranportista" prop="creaTranportista">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueCreaTransportista"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="SI"
                inactive-text="NO"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<style scoped>
.aiuda {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}

.ocultarMostrarCard {
  display: none;
}

.mostrarOcultarCard {
  display: block;
}

@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }

  .mostrarOcultarCard {
    display: none;
  }
}
</style>
<script>
let id = 0;
export default {
  name: "nuevo-tipo-centro",
  data() {
    let _self = this;
    return {
      puntosCardinales: [
        {
          value: "N",
          label: "N",
        },
        {
          value: "S",
          label: "S",
        },
        {
          value: "W",
          label: "W",
        },
        {
          value: "E",
          label: "E",
        },
      ],
      valueCardinal: "",

      form: {
        razonSocial: null,
        cuit: null,
        domicilio: null,
        pais: null,
        provincia: null,
        departamentoID: null,
        departamento: null,
        localidadID: null,
        localidad: null,
        latitud: null,
        longitud: null,
        tipo: null,
        telefono: null,
        email: null,
        traslado: null,
        gradosLat: null,
        minutosLat: null,
        segundosLat: null,
        valueCardinalLat: null,
        gradosLon: null,
        minutosLon: null,
        segundosLon: null,
        valueCardinalLon: null,
        valueCreaTransportista: false,
      },

      impedirClose: false,
      persona: null,

      urlTiposBeneficiarios: "/tipoBeneficiario/obtenerTodosSelect",

      valueFormatoDecimales: true,
      formRules: {
        razonSocial: [
          {
            required: true,
            message: "Por favor introduzca la razon social.",
            trigger: "change",
          },
        ],
        cuit: [
          {
            required: true,
            message: "Por favor introduzca el CUIT.",
            trigger: "change",
          },
        ],

        gradosLat: [
          {
            required: true,
            message: "Por favor introduzca grados.",
            trigger: "change",
          },
        ],
        minutosLat: [
          {
            required: true,
            message: "Por favor introduzca minutos.",
            trigger: "change",
          },
        ],
        segundosLat: [
          {
            required: true,
            message: "Por favor introduzca segundos.",
            trigger: "change",
          },
        ],
        valueCardinalLat: [
          {
            required: true,
            message: "Por favor introduzca dirección.",
            trigger: "change",
          },
        ],
        gradosLon: [
          {
            required: true,
            message: "Por favor introduzca grados.",
            trigger: "change",
          },
        ],
        minutosLon: [
          {
            required: true,
            message: "Por favor introduzca minutos.",
            trigger: "change",
          },
        ],
        segundosLon: [
          {
            required: true,
            message: "Por favor introduzca segundos.",
            trigger: "change",
          },
        ],

        valueCardinalLon: [
          {
            required: true,
            message: "Por favor introduzca dirección.",
            trigger: "change",
          },
        ],

        domicilio: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: true,
            message: "Por favor introduzca la latitud.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: true,
            message: "Por favor introduzca la longitud.",
            trigger: "change",
          },
        ],
        localidadID: [
          {
            required: true,
            message: "Por favor introduzca la localidad.",
            trigger: "change",
          },
        ],
        departamentoID: [
          {
            required: true,
            message: "Por favor introduzca el departamento.",
            trigger: "change",
          },
        ],
        telefono: [
          {
            required: true,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],

        email: [
          {
            required: true,
            message: "Por favor introduzca el email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor introduzca un email correcto",
            trigger: ["blur", "change"],
          },
        ],
        tipo: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change",
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1: true,
      value2: true,
      valueTraslados: true,
      ubicacionActual: true,
      ubicacionIncompleta: false,
      valueCreaTransportista: false,
      visible: false,
      edad: "",
      yaEligioFecha: false,
      eligioPais: false,

      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,

      departamentosSelect: [],
      localidadesSelect: [],
      cargandoLocalidades: false,
    };
  },
  created() {
    this.getDepartamentos();
  },
  methods: {
    //convertir grados min seg y cardinalidad a lat y lng
    ConvertDMSToDD(degrees, minutes, seconds, direction) {
      var dd = degrees + minutes / 60 + seconds / (60 * 60);

      if (direction == "S" || direction == "W") {
        dd = dd * -1;
      } // Don't do anything for N or E
      return dd;
    },
    ParseDMS(input) {
      var parts = input.split(/[^\d\w]+/);

      var lat = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
      var lng = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7]);
    },
    getPais(addrComponents) {
      for (var i = 0; i < addrComponents.length; i++) {
        if (addrComponents[i].types[0] == "country") {
          return addrComponents[i].short_name;
        }
        if (addrComponents[i].types.length == 2) {
          if (addrComponents[i].types[0] == "political") {
            return addrComponents[i].short_name;
          }
        }
      }
      return false;
    },

    async verificarCoordenadas() {
      let resultado;
      try {
        let geocoder = new google.maps.Geocoder(),
          latlng = new google.maps.LatLng(
            this.form.latitud,
            this.form.longitud
          );
        const result = await geocoder.geocode(
          { latLng: latlng },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                resultado = { status: "OK", result: results[1] };
              } else {
                resultado = { status: "INVALID", result: results };
              }
            } else {
              //failed

              resultado = { status: "INVALID", result: results[1] };
            }
          }
        );
      } catch (error) {
        this.$message({
          message:
            "Error al utilizar los servicios de google. Detalle:" +
            error.message,
          type: "error",
        });
        return false;
      }

      console.log("resultado", resultado);
      if (resultado.status != null && resultado.status == "OK") {
        const pais = this.getPais(resultado.result.address_components);
        if (pais != null && pais == "AR") {
          return true;
        } else {
          this.$message({
            message:
              "Se encuentra fuera de argentina, seleccione otras coordenadas.",
            type: "error",
          });
        }
        return false;
      } else {
        this.$message({
          message: "Coordenadas invalidas.",
          type: "error",
        });
        return false;
      }
    },
    onPasteLat() {
      let latLong = this.form.latitud;
      latLong = latLong.split(", ");
      this.form.latitud = latLong[0];
      this.form.longitud = latLong[1];
      console.log(latLong);
    },
    onPasteLong() {
      let latLong = this.form.longitud;
      latLong = latLong.split(", ");
      this.form.latitud = latLong[0];
      this.form.longitud = latLong[1];
      console.log(latLong);
    },
    cambioDepartamento() {
      console.log(this.form.departamentoID);
      this.getLocalidades();
    },
    cambioLocalidad() {
      console.log(this.form.localidadID);
      console.log(this.form);
    },
    async getDepartamentos() {
      let params = {
        provinciaID: 1,
        sinPaginar: 1,
      };
      await this.$maca.api.get(this.urlDepartamento, params).then((res) => {
        this.departamentosSelect = res.datos;
      });
    },
    async getLocalidades() {
      this.cargandoLocalidades = true;
      console.log(this.form.departamentoID);
      let params = {
        departamentoID: this.form.departamentoID,
        sinPaginar: 1,
      };
      await this.$maca.api
        .get("/localidad/obtenerTodos", params)
        .then((res) => {
          this.localidadesSelect = res.datos;
          console.log(this.localidadesSelect);
        });
      this.cargandoLocalidades = false;
    },
    resetearValor(valor) {
      if (valor == 1) {
        this.$refs.refProvincia.recargar();
        this.$refs.refProvinciaMobile.recargar();
        this.form.provincia = null;
        this.form.departamento = null;
        this.form.localidad = null;
        this.eligioPais = true;
      } else if (valor == 2) {
        this.$refs.refDepartamento.recargar();
        this.$refs.refDepartamentoMobile.recargar();
        this.form.departamento = null;
        this.form.localidad = null;
      } else if (valor == 3) {
        this.$refs.refLocalidad.recargar();
        this.$refs.refLocalidadMobile.recargar();
        this.form.localidad = null;
      }
    },
    calcularEdad() {
      let fecha1 = new Date(this.form.fechaN.toString());
      let fecha2 = new Date();
      var edad = fecha2.getFullYear() - fecha1.getFullYear();
      var m = fecha2.getMonth() - fecha1.getMonth();

      if (m < 0 || (m === 0 && fecha2.getDate() < fecha1.getDate())) {
        edad--;
        if (fecha2.getFullYear() == fecha1.getFullYear()) {
          edad++;
        }
      }
      this.edad = edad;
      this.yaEligioFecha = true;
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          console.log("afterSubmit()", afterSubmit());
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$router.go(-1);
        }
      });
    },
    async postApi() {
      if (!this.valueFormatoDecimales) {
        this.form.latitud = this.ConvertDMSToDD(
          this.form.gradosLat,
          this.form.minutosLat,
          this.form.segundosLat,
          this.form.valueCardinalLat
        );
        this.form.longitud = this.ConvertDMSToDD(
          this.form.gradosLon,
          this.form.minutosLon,
          this.form.segundosLon,
          this.form.valueCardinalLon
        );
      }
      const result = await this.verificarCoordenadas();
      if (result == false) {
        return false;
      }
      let detalleTipoBeneficiario = [
        { id: 0, tipoBeneficiarioID: this.form.tipo.id, accion: "A" },
      ];

      // let latitudDMS;
      // let longitudDMS;
      // if (!this.valueFormatoDecimales) {
      //   this.form.latitud = null;
      //   this.form.longitud = null;
      //   latitudDMS = {
      //     grados: this.form.gradosLat,
      //     minutos: this.form.minutosLat,
      //     segundos: this.form.segundosLat,
      //     direccion: this.form.valueCardinalLat,
      //   };

      //   longitudDMS = {
      //     grados: this.form.gradosLon,
      //     minutos: this.form.minutosLon,
      //     segundos: this.form.segundosLon,
      //     direccion: this.form.valueCardinalLon,
      //   };
      // }

      //quitar marcara al cuit
      let cuitcuilFormateado = this.form.cuit
        .replaceAll("-", "")
        .replaceAll(".", "")
        .replaceAll(" ", "");

      // Hacer Post
      let params = {
        razonSocial: this.form.razonSocial,
        cuit: cuitcuilFormateado,
        domicilio: this.form.domicilio,
        email: this.form.email,

        // latitud: this.form.latitud != null ? parseFloat(this.form.latitud) : "",
        // longitud:
        //   this.form.longitud != null ? parseFloat(this.form.longitud) : "",
        // latitudDMS: latitudDMS != null ? JSON.stringify(latitudDMS) : "",
        // longitudDMS: longitudDMS != null ? JSON.stringify(longitudDMS) : "",

        telefono: this.form.telefono,
        traslado: this.valueTraslados,
        detalleCentroTipoBeneficiario: JSON.stringify(detalleTipoBeneficiario),
        crearTransportista: this.valueCreaTransportista,
      };

      let latitudDMS;
      let longitudDMS;
      if (!this.valueFormatoDecimales) {
        delete params.latitud;
        delete params.longitud;
        latitudDMS = {
          grados: this.form.gradosLat,
          minutos: this.form.minutosLat,
          segundos: this.form.segundosLat,
          direccion: this.form.valueCardinalLat,
        };
        longitudDMS = {
          grados: this.form.gradosLon,
          minutos: this.form.minutosLon,
          segundos: this.form.segundosLon,
          direccion: this.form.valueCardinalLon,
        };
        params.latitudDMS = JSON.stringify(latitudDMS);
        params.longitudDMS = JSON.stringify(longitudDMS);
      } else {
        params.latitud = this.form.latitud;
        params.longitud = this.form.longitud;
        params.latitudDMS = null;
        params.longitudDMS = null;
      }

      if (this.form.departamentoID != null) {
        params.departamentoID = this.form.departamentoID;
      } else {
        this.$message({
          message: "Ingrese el departamento",
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      if (this.form.localidadID != null) {
        params.localidadID = this.form.localidadID;
      } else {
        this.$message({
          message: "Ingrese la localidad",
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      let respuestaApi = await this.$maca.api.post("/centro/crear", params);

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Tipo de Centro creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>
